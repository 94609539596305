
































import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import CompanyService from "@/services/CompanyService";
import CustomerService from "@/services/CustomerService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import { Component, Vue } from "vue-property-decorator";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
	components: { AlertDialogView },
	data: () => ({
		title: ""
	})
})
export default class CustomerNavigatorView extends Vue {
	private alertDialog = new AlertDialogProperties();
	private customerService = new CustomerService();
    private companyService = new CompanyService();

	public async created() {
        await this.loadCompany();
		await this.navigate();
	}

	public async navigate() {
		try {			
        	const query = this.$route.query;
			const facebookAccountId = Number(query.facebookAccountId) ?? 0;
			if (facebookAccountId === 0) {
				var m: any = this.$t('message.invalid-facebook-account-id');
				throw new Error(m.toString());
			}

			const r = await this.customerService.post({ 
				facebookAccountId,
				silent: true
			});
			const url = `/management/customers/modify?id=${r.data.id}`;
			await this.$router.replace(url);
		} catch (e) {
			if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
				this.alertDialog.tag = { command: "navigate-error" };
                this.alertDialog.visible = true;
            }
		}
	}

    public async loadCompany() {
        try {
            const r = await this.companyService.summary();
            document.title = r.data.name;
            this.$data.title = document.title;
        } catch (e) {
            document.title = this.$t('title.app').toString();
            this.$data.title = document.title;
        }
    }

    public async alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
        this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            const command = tag.command;
            if (command === "navigate-error") {
                await this.$router.replace("/home");
            }
        }
    }
}
